<template>
  <component :is="'PageWithColoredCenteredBox'">
    <div class="page-centered-box page-how-it-works">
      <h2 class="h-2">{{ $t('page-how-it-works.title') }}</h2>
      <p class="margin-top-s text-center" v-html="$t('page-how-it-works.text')"></p>
      <div class="container-lottie" :class="{'container-lottie-mobile': getDeviceType() === deviceTypes.MOBILE || getDeviceType() === deviceTypes.TABLET}">
        <img class="margin-top-m how-it-works" src="@/assets/img/test.png" alt="How it works"/>
        <div class="bg-lottie-down text-center" :class="{'bg-lottie-down-mobile': getDeviceType() === deviceTypes.MOBILE || getDeviceType() === deviceTypes.TABLET}">
          <Vue3Lottie v-if="getDeviceType() === deviceTypes.MOBILE || getDeviceType() === deviceTypes.TABLET"
                      :width="lottieOptions.widthWeb" class="margin-top-m img-responsive how-it-works"
                      :animation-data="lottieOptions.animationDataMobile"/>
          <p v-if="getDeviceType() === deviceTypes.MOBILE || getDeviceType() === deviceTypes.TABLET">
            {{ getLanguage() === "da" ? "Stryg nedad" : "Swipe down" }}</p>
          <Vue3Lottie v-else :width="lottieOptions.widthWeb"
                      class="lottie-keydown margin-top-m img-responsive how-it-works"
                      :animation-data="lottieOptions.animationDataWeb"/>
          <p v-if="getDeviceType() !== 'mobile' && getDeviceType() !== 'tablet'">{{ getLanguage() === "da" ? "Ned-tast" : "Down key" }}</p>
        </div>
      </div>
      <button class="btn primary-bg body-bold margin-top-xl small-btn"
              v-on:click="launchTest">
        {{ $t('page-how-it-works.button-text') }}
      </button>
    </div>
  </component>
</template>
<script>
import PageWithColoredCenteredBox from "@/components/pages/generic/PageWithColoredCenteredBox";
import {Vue3Lottie} from "vue3-lottie";
import {getDeviceType} from "@/utils/utils";
import {deviceTypes} from "@/constants";

export default {
  computed: {
    deviceTypes() {
      return deviceTypes
    }
  },
  components: {Vue3Lottie, PageWithColoredCenteredBox},
  data() {
    return {
      lottieOptions: {
        animationDataWeb: require('@/assets/lotties/keyDown.json'),
        animationDataMobile: require('@/assets/lotties/swipeDown.json'),
        widthWeb: 60
      }
    };
  },
  methods: {
    getDeviceType,
    launchTest() {
      this.$changePageWithAnalyticsStep(this.$store, 'PageTest');
    },
    getLanguage() {
      return this.$store.state.language;
    },
  },
};
</script>
